const WAITLIST_ID = 16531;
const apiKey =
    "patjDMnLVmQZnWA56.c8a4308b29d5ef12f5b29d3e57b069154db29db70159956cb30b5d8119ee73b0"
const baseId = "app1uZsk5B30MygkG"
const tableName = "List"
const airtableTableUrl = `https://api.airtable.com/v0/${baseId}/${tableName}`

const boostCodeConversions = {
    boostGrades: 'Grades',
    boostName: 'Full Name',
    boostTitle: 'Title',
    boostStudents: 'Students',
    boostSchoolName: 'School',
}

// Get waitlist version
// export const getUser = async (idToUse) => {
//     /* curl --request GET \
//   --url https://api.getwaitlist.com/api/v1/signup?waitlist_id=12345&email=example9911@example.com \
//   --header 'Content-Type: application/json' \
  
// */
//     const isEmail = idToUse.includes('@');

//     let url = `https://api.getwaitlist.com/api/v1/signup`
//     url += `?waitlist_id=${WAITLIST_ID}`
//     url += isEmail ? `&email=${idToUse}` : `&uuid=${idToUse}`

//     const response = await fetch(url, {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });

//     return response.json();
// }


export const getUser = async (email) => {
    
    // Airtable API URL with filterByFormula to find a record by email
    const url = `${airtableTableUrl}?filterByFormula={email}="${email}"`;

    // Fetch options
    const options = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${apiKey}`,
            'Content-Type': 'application/json'
        }
    };

    try {
        // Send the request to Airtable
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.records.length > 0) {
            console.log('Record found:', result.records[0]);
        } else {
            console.log('No record found with the given email.');
            return 'error';
        }

        console.log(result.records[0].fields)

        const user = {
            verified: result.records[0].fields['Verified'],
            id: result.records[0].fields['ID'],
            position: result.records[0].fields['Calculated Position'],
            uid: result.records[0].id,
            referredBy: result.records[0].fields['Referred By'],
            amountReferred: result.records[0].fields['Amount Referred'],
            email: result.records[0].fields['Email'],
            boostGrades: result.records[0].fields['Grades'],
            boostName: result.records[0].fields['Full Name'],
            boostTitle: result.records[0].fields['Title'],
            boostStudents: result.records[0].fields['Students'],
            boostSchoolName: result.records[0].fields['School Name'],
        }

        console.log({ user })

        return user;
    } catch (error) {
        console.error('Error:', error);
    }

}

export const confirmEmail = async (uid) => {
    const result = await fetch("https://karavil-confirm_waitlist_email.web.val.run", {
        body: JSON.stringify({ uid }),
        method: "POST",
    })
    if (!result.ok) {
        console.error("Error confirming email", await result.text());
    }
}

export const addBoostMetadata = async (uid, field, value) => {
    const fieldName = boostCodeConversions[field];

    const result = await fetch("https://karavil-addBoostMetadata.web.val.run", {
        body: JSON.stringify({ uid, fieldName, value }),
        method: "POST",
    })
    if (!result.ok) {
        console.error("Error adding boost metadata for field " + field, await result.text());
    }
}


export const addPersonalEmail = async (uid, email, personalEmail) => {
    const lowerCasePersonalEmail = personalEmail.toLowerCase();
    const result = await fetch("https://karavil-addpersonalemailtorowandsendconfirm.web.val.run", {
        body: JSON.stringify({ uid, email, personalEmail: lowerCasePersonalEmail }),
        method: "POST",
    })
    if (!result.ok) {
        console.error("Error adding personal email", await result.text());
    }
}


export const signUp = async (email, metadata) => {
    let url = `https://api.getwaitlist.com/api/v1/signup`
    const data = {
        email,
        waitlist_id: WAITLIST_ID,
        metadata: metadata,
    }

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    });

    return response.json();
}

export const deleteUser = async (uuid) => {
    /* curl --request DELETE \
--url https://api.getwaitlist.com/api/v1/signup \
--header '{
Content-Type: application/json,
Authorization: Bearer qwertyuiop1234567890
}'
--data '{
"waitlist_id": 123,
"signups": [
{"uuid": "a7aa6ae2-3c42-4fe1-a15c-2652c93022b4"}
]
}' */
    let url = `https://api.getwaitlist.com/api/v1/signup`
    const data = {
        waitlist_id: WAITLIST_ID,
        signups: [
            { uuid }
        ]
    }
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'api-key': `928f0660998c9a5824869650988e2401f6ffbb206e5fd3d8ae88c27bf06e118f`
        },
        body: JSON.stringify(data)
    });
    
    if (!response.ok) {
        console.error("HTTP error", response.status, await response.text());
        return;
    }
}

export const getWaitlistLength = async () => {
    let url = `https://api.getwaitlist.com/api/v1/waitlist/${WAITLIST_ID}`
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return (await response.json()).statistics['current_waiters']
}

// export const getAccessToken = async () => {
//     let url = `https://api.getwaitlist.com/api/v1/auth/create_tokens`
//     const data = {
//         email: process.env.WAITLIST_USER,
//         password: process.env.WAITLIST_PASSWORD,
//     }

//     const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data)
//     });

//     return response.json();
// }